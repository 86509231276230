<template>
  <a-card class="card rent" :bordered="false">
    <base-page
      :queryFields="queryFields"
      :columns="columns"
      :rules="rules"
      :modelTitle="modelTitle"
      :formFields="formFields"
      :moduleName="moduleName"
      :getPageFun="getFmsMetaPage"
      :deleteFun="deleteFmsMetaInfo"
      :createFun="createFmsMetaInfo"
      :updateFun="updateFmsMetaInfo">
    </base-page>
  </a-card>
</template>
<script>
import { BasePage } from '@/components'
import { getFmsMetaPage, createFmsMetaInfo, updateFmsMetaInfo, deleteFmsMetaInfo } from '@/api/fms'
export default {
  components: {
    BasePage
  },
  data() {
    return {
      queryFields: [
        { label: '开户行', name: 'bank', fieldType: '', queryType: '%' },
        { label: '银行账号', name: 'bank_account', fieldType: '', queryType: '%' }
      ],
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          width: 50,
          align: 'center'
        },
        {
          title: 'SAP代码',
          dataIndex: 'sap_code'
        },
        {
          title: '公司代码',
          dataIndex: 'company_code'
        },
        {
          title: '开户行',
          dataIndex: 'bank'
        },
        {
          title: '银行账号',
          dataIndex: 'bank_account'
        },
        {
          title: '描述',
          dataIndex: 'bank_describe'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 120,
          scopedSlots: { customRender: 'action' }
        }
      ],
      rules: {
        sap_code: [
          { required: true, message: 'SAP代码', trigger: 'blur' }
        ],
        company_code: [
          { required: true, message: '公司代码', trigger: 'blur' }
        ],
        bank: [
          { required: true, message: '开户行', trigger: 'blur' }
        ],
        bank_account: [
          { required: true, message: '银行账号', trigger: 'blur' }
        ]
      },
      modelTitle: '银行管理',
      formFields: [
        {
          label: 'SAP代码', name: 'sap_code', type: 'default'
        },
        {
          label: '公司代码', name: 'company_code', type: 'default'
        },
        {
          label: '开户行', name: 'bank', type: 'default'
        },
        {
          label: '银行账号', name: 'bank_account', type: 'default'
        },
        {
          label: '描述', name: 'bank_describe', type: 'default'
        }
      ],
      moduleName: 'fms_bank'
    }
  },
  methods: {
    getFmsMetaPage,
    createFmsMetaInfo,
    updateFmsMetaInfo,
    deleteFmsMetaInfo
  }
}
</script>
